import consumer from "./consumer"

consumer.subscriptions.create("ProcessorChannel", {
    connected() {
        // Called when the subscription is ready for use on the server
        console.log("Connected to processor");
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
    },

    received(data) {
        // Called when there's incoming data on the websocket for this channel
        console.log('Recibiendo datos de la app');
        console.log(data);

        if ( data.only_text ) {
            $("#channel-cable-p").text( data.text );
            $("#channel-cable-progress-bar").css( 'width', data.work_percent+'%' );
        } else {
            $("#channel-cable-h1").text( data.title );
            $("#channel-cable-p").text( data.text );
        }

        if ( data.work_percent == 100 ) {
            $("#channel-cable-progress-bar").css( 'width', '100%' );
        }

    }
});
