// process_drivers.js

import Dropzone from "dropzone";

Dropzone.autoDiscover = false;

// let my_dropzone = new Dropzone("#my-form");
// my_dropzone.on( "addedfile", file => {
//     console.log ( `File added: ${file.name}` );
// });

$(function(){

    const auth_token = localStorage.getItem('auth_token');
    const locale = $("#locale").val();
    const values = {"locale": locale, "authenticity_token": $("#authenticity_token").val() }

    if ($(".dropzone").length) {

        $(".dropzone").on( 'dragover', () => {
            console.log("MOUSEOVER");
            $(".dropzone").css( 'background-color', 'rgba(13, 110, 253, 30)' );
        });
        $(".dropzone").on( 'dragleave', () => {
            console.log("MOUSEOUT");
            $(".dropzone").css( 'background-color', 'rgb(238, 238, 238)' );
        });

        const auth_token = localStorage.getItem('auth_token');
        let uploader = new Dropzone(".dropzone",{
            url: "/es/process_drivers/api/v1/upload",
            dictDefaultMessage: 'Arrastre aqui un nuevo fichero de excel con los datos',
            dictRemoveFile: 'Eliminar fichero',
            paramName : "uploadedFiles",
            uploadMultiple :false,
            acceptedFiles : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            addRemoveLinks: true,
            forceFallback: false,
            maxFilesize:5000,
            parallelUploads: 1,
            params: { "authenticity_token": $("#authenticity_token").val() },
            //headers: { "Authorization":'Bearer' + auth_token }
            headers: { Authorization: `bearer ${auth_token}` }
        });//end drop zone

        uploader.on("drop", function(file,response) {
            console.log(response);

            $("#process-response-zone-ok").hide();
            $("#process-response-no-freelance").hide();

            $("#process-response-zone").hide();
            $("#process-response-table tbody").html("");

            spin_in();
            $("#channel-cable-progress-bar").css( 'width', '0%' );
            $("#channel-cable").fadeIn(500);
        });

        uploader.on("success", function(file,response) {
            $(".dropzone").css( 'background-color', 'rgb(255, 255, 255)' );
            $("#channel-cable").fadeOut(500);
            $("#channel-cable-h1").text('');
            $("#channel-cable-p").text('');
            setTimeout( () => {
                spin_out();
            }, 300 )
            
            $("#process-response-zone-alert").hide();
            console.log(response);
            process_show_errors_on_process_data(response);

            // if ( response.services.length == 0 ) {
            //     const year_month = data.year + " - " + data.month_txt;
            //     $(".show-file-info").text(year_month);
            //     $("#file-year").val(data.year);
            //     $("#file-month").val(data.month);
            //     $("#process-response-zone-ok").fadeIn(300);
            // } else {
            //     process_show_errors_on_process_data(response);
            // }
        });

        uploader.on("complete", function(file, response){
            this.removeAllFiles(true);
        });

    }

    $("#use-last-uploaded-file").on( 'click', () => {
        use_last_uploaded_file(auth_token, values);
    });

    //// Process incorporated excel services
    $("#process-no-freelance-drivers").on ( 'click', () => {
        $("#process-response-freelance").hide();
        values["year"] = $("#file-year").val();
        values["month"] = $("#file-month").val();
        process_no_freelance_drivers(auth_token, values);
    });
    
    //// Process incorporated excel services
    $("#process-freelance-drivers").on ( 'click', () => {
        $("#process-response-no-freelance").hide();
        values["year"] = $("#file-year").val();
        values["month"] = $("#file-month").val();
        process_freelance_drivers(auth_token, values);
    });

    //// Process incorporated excel services
    $("#process-statistics-drivers").on ( 'click', () => {
        $("#process-response-no-freelance").hide();
        process_statistics_drivers(auth_token, values);
    });

    $("#process-send-mail-btn").on ( 'click', () => {
        let ids = $('input:checked').map(function(i, e) {return e.value}).toArray();
        values.ids = ids;
        process_no_freelance_drivers_send_mail(auth_token, values);
    });

    $("#process-send-mail-freelance-btn").on ( 'click', () => {
        let ids = $('input:checked').map(function(i, e) {return e.value}).toArray();
        let drivers_list = construct_mail_freelance_object(ids);
        values.drivers_list = drivers_list;
        process_freelance_drivers_send_mail(auth_token, values);
    });

    $("#process-monthly-download-invoices").on ( 'click', (event) => {
        event.preventDefault();
        values["year"] = $("#file-year").val();
        //values["month"] = $("#file-month").val();
        process_download_invoces_resume(auth_token, values);
    });

    $("#process-monthly-download-statistics").on( 'click', (event) => {
        event.preventDefault();
        values["year"] = $("#file-year").val();
        //values["month"] = $("#file-month").val();
        process_download_statistics_resume(auth_token, values);
    });

    $(".process-monthly-download-invoices-general").on( 'click', function(event) {
        event.preventDefault();
        const year = $(this).data('year');
        values["year"] = year;
        process_download_invoces_resume(auth_token, values);
    });

    $(".process-monthly-download-statistics-general").on( 'click', function(event) {
        event.preventDefault();
        const year = $(this).data('year');
        values["year"] = year;
        process_download_statistics_resume(auth_token, values);
    });
    
});//end jQuery

// Close opened no freelance driver details
window.close_opened_driver_details = (driver_id) => {
    $("#process-details-days-"+driver_id).hide();
}

window.process_show_errors_on_process_data = (data) => {

    const year_month = data.year + " - " + data.month_txt;
    $(".show-file-info").text(year_month);
    $("#file-year").val(data.year);
    $("#file-month").val(data.month);
    $("#process-monthly-statistics-drivers").attr( 'year', data.year );
    $("#process-monthly-statistics-drivers").attr( 'month', data.month );

    const services = data.services;

    if (services.length == 0) {
        $("#process-response-zone-ok").fadeIn(300);
    } else {
        let html = '';
        for ( let i = 0; i < services.length; i++ ){
            html += '<tr class="preprocess-all preprocess-driver-id-' + services[i].driver_id + '">';
            html +=     '<th scope="row">' + services[i].driver_id + '</th>';
            html +=     '<td>' + services[i].driver + '</td>';
            html +=     '<td>' + services[i].service + '</td>';
            html +=     '<td>' + services[i].status + '</td>';
            html +=     '<td>' + services[i].excel_line + '</td>';
            html +=     '<td style="color: red;"><a href="javascript:process_remove_drivers_from_list(\'' + services[i].driver_id + '\')"><i class="fas fa-eraser fa-2x"></i></a></td>';
            html += '</tr>';
        }

        $("#process-response-table tbody").html(html);
        $("#process-response-zone-alert").fadeIn(300);
    }

}

window.process_remove_drivers_from_list = (id) => {
    $(".preprocess-driver-id-" + id).remove();
    let preprocess_all = $( ".preprocess-all" );
    if ( preprocess_all.length == 0 ) {
        $("#process-response-zone-alert").fadeOut(300);
        setTimeout(() => {
            $("#process-response-zone-ok").fadeIn(300);
        }, 300);
    }
}

window.process_remove_drivers_from_list_all = () =>{
    $(".preprocess-all").remove();
    $("#process-response-zone-alert").fadeOut(300);
    setTimeout(() => {
        $("#process-response-zone-ok").fadeIn(300);
    }, 300);
}

window.process_no_freelance_drivers = (auth_token, values) => {

    $("#process-response-zone").hide();
    $("#process-response-freelance").hide();
    $('input:checked').prop('checked', false); //uncheck all mail checher
    spin_in();
    $("#channel-cable-progress-bar").css( 'width', '0%' );
    $("#channel-cable").fadeIn(500);

    fetch('/'+values.locale+'/process_drivers/api/v1/process_no_freelance_drivers',
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(values),
        },
        10000
    )

    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log(data);
        spin_out();
        if ( data.status == 401 ) {
            toast_msg(data.error_msg, 'warning');
        } else {
            const summary = data.summary;

            let html = '';
            for ( let i=0; i<summary.length; i++ ) {
                html += '<tr>';
                html +=     '<td><a href="javascript:no_freelance_details_wrapper_open_close(\'' + summary[i].driver_id + '\')" type="button" class="btn btn-primary text-white"><i class="fas fa-info-circle fa-2x"></i></button></td>';
                html +=     '<td>' + summary[i].driver_name + ' (' + summary[i].driver_excess + ')</td>';
                html +=     '<td class="text-center">' + summary[i].summary.total_services + '</td>';
                html +=     '<td class="text-center">' + summary[i].summary.total_extra_services + '</td>';
                html +=     '<td class="text-center">' + summary[i].summary.total_extra_services_amount + ' €</td>';
                html +=     '<td><div class="form-check form-switch form-switch-md text-center"><input class="form-check-input send-mail-checkbox" type="checkbox" name="driver_ids" value="' + summary[i].driver_id + '"></div></td>';
                html += '</tr>';
                html += '<tr class="process-show-driver-info" id="no-freelance-details-wrapper-' + summary[i].driver_id + '" style="display: none;"><td colspan="6">';

                // Here display no freelance details
                let days = summary[i].days;
                for ( let j=0; j<days.length; j++ ) {

                    let days_services_list = days[j].day_services_list;
                    for ( let k=0; k<days_services_list.length; k++ ) {

                        html += '<table id="process-no-freelance-list" class="table table-bordered table-hover" start-load="0">';
                        html += '    <thead>';
                        html += '        <tr style="color: white; background-color: #505050;">';
                        html += '            <th>FECHA: '+days_services_list[k].date+'</th>';
                        html += '            <th style="text-align: center;">Horas: '+days_services_list[k].work_time+'</th>';
                        html += '            <th style="text-align: center;">Comienza: '+days_services_list[k].worker_start+'</th>';
                        html += '            <th style="text-align: center;">Termina: '+days_services_list[k].worker_end+'</th>';
                        html += '        </tr>';
                        html += '        <tr>';
                        html += '            <th>Servicio</th>';
                        html += '            <th style="text-align: center;">Matrícula</th>';
                        html += '            <th style="text-align: center;">Recogida</th>';
                        html += '            <th style="text-align: center;">Entrega</th>';
                        html += '        </tr>';
                        html += '    </thead>';
                        html += '    <tbody>';

                        let services = days_services_list[k].day_services_list;
                        for ( let l=0; l<services.length; l++ ) {
                            if (services[l].is_extra) {
                                html += '<tr style="color: red;">';
                            } else {
                                html += '<tr>';
                            }
                                html += '<td>'+services[l].service+'</td>';
                                html += '<td style="text-align: center;">'+services[l].numberplate+'</td>';
                                html += '<td style="text-align: center;">'+services[l].pickup+'</td>';
                                html += '<td style="text-align: center;">'+services[l].delivery+'</td>';
                            html += '</tr>';
                        }

                        html += '    </tbody>';
                        html += '</table>'

                    }

                }


                html += '</td></tr>';
            }

            $("#process-no-freelance-list tbody").html(html);
            $("#process-response-no-freelance").fadeIn(300);

            $("#channel-cable").fadeOut(500);
            $("#channel-cable-h1").text('');
            $("#channel-cable-p").text('');
            setTimeout( () => {
                spin_out();
            }, 300 );

        }

    })
    .catch((error) => {
        $("#channel-cable").hide();
        $("#channel-cable-h1").text('');
        $("#channel-cable-p").text('');
        spin_out();
        error_msg(error);
    })
}

window.no_freelance_details_wrapper_open_close = (driver_id) => {
    $("#no-freelance-details-wrapper-"+driver_id).toggle();
}

window.process_no_freelance_drivers_send_mail = (auth_token, values) => {

    $("html, body").animate({ scrollTop: 0 }, "slow");
    $("#process-response-zone").hide();

    spin_in();
    $("#channel-cable-progress-bar").css( 'width', '0%' );
    $("#channel-cable").fadeIn(500);

    fetch('/'+values.locale+'/process_drivers/api/v1/process_no_freelance_drivers_send_mail',
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(values),
        },
        10000
    )

    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log(data);
        
        $("#channel-cable").fadeOut(500);
        $("#channel-cable-h1").text('');
        $("#channel-cable-p").text('');
        setTimeout( () => {
            spin_out();
        }, 300 );

        let mails_list = '';
        let names = data.drivers_names;
        for ( let i = 0; i < names.length; i++ ) {
            mails_list += '<li>' + names[i] + '</li>';
        }

        Swal.fire({
            title: '<h2>' + names.length + ' Emails enviados</h2>',
            icon: 'info',
            html:'<p>Se ha enviado email a los siguientes conductores:</p><ul style="text-align: left;">' + mails_list + '</ul>',
            showCloseButton: true
        });

    })
    .catch((error) => {
        $("#channel-cable").hide();
        $("#channel-cable-h1").text('');
        $("#channel-cable-p").text('');
        spin_out();
        error_msg(error);
    })
}

window.process_freelance_drivers = (auth_token, values) => {

    $("#process-response-zone").hide();
    $("#process-response-no-freelance").hide();
    $('input:checked').prop('checked', false); //uncheck all mail checher
    spin_in();
    $("#channel-cable-progress-bar").css( 'width', '0%' );
    $("#channel-cable").fadeIn(500);

    fetch('/'+values.locale+'/process_drivers/api/v1/process_freelance_drivers',
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(values),
        },
        10000
    )

    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log(data);
        spin_out();
        if ( data.status == 401 ) {
            toast_msg(data.error_msg, 'warning');
        } else {
            const summary = data.summary;

            let html = '';
            for ( let i=0; i<summary.length; i++ ) {
                let services_standar = summary[i].details.services;
                let services_itv = summary[i].details.services_itv;
                let services_transit =  summary[i].details.services_transit;

                html += '<tr>';
                html +=     '<td><a href="/'+values.locale+'/process_drivers/api/v1/process_open_invoice_pdf/' + summary[i].invoice_id + '" type="button" class="btn btn-success text-white" target="_blank"><i class="fas fa-receipt fa-2x"></i></a></td>';
                html +=     '<td><a href="javascript:open_freelance_details(\'' + summary[i].driver_id + '\')" type="button" class="btn btn-primary text-white"><i class="fas fa-info-circle fa-2x"></i></a></td>';
                html +=     '<td>' + summary[i].driver_name + '</td>';
                html +=     '<td class="text-center">' + summary[i].total_services + '</td>';
                html +=     '<td class="text-center">' + summary[i].services_failed + '</td>';
                html +=     '<td class="text-center">' + summary[i].total_amount + ' €</td>';
                html +=     '<td><div class="form-check form-switch form-switch-md text-center"><input class="form-check-input send-mail-checkbox" type="checkbox" name="driver_ids" value="' + summary[i].driver_id + '"></div></td>';
                html += '</tr>';

                html += '<tr>';
                html +=     '<td colspan="4"><div class="form-group"><input type="text" placeholder="Texto" class="form-control variable-text" id="variable_text_' + summary[i].driver_id + '" value="' + summary[i].variables.variable_text + '"></div></td>';
                html +=     '<td colspan="2"><div class="form-group"><input type="number" placeholder="0.00" step="0.01" class="form-control variable-amount" id="variable_amount_' + summary[i].driver_id + '" value="' + summary[i].variables.variable_amount + '"></div></td>';
                html +=     '<td colspan="1" id="variable_btn_wrapper_' + summary[i].driver_id + '">';

                if ( summary[i].variables.id == 0 ) {
                    html +=         '<a href="javascript:process_upload_freelance_variables(\'' + summary[i].driver_id + '\');"><i class="fas fa-upload fa-2x"></i></a>';
                } else {
                    html +=         '<a href="javascript:process_delete_freelance_variables(\'' + summary[i].variables.id + '\');"><i class="fas fa-times-circle fa-2x"></i></a>';
                }

                html +=     '</td>';
                html += '</tr>';

                html += '<tr class="process-show-driver-info" id="process-freelance-details-' + summary[i].driver_id + '" style="display: none;">';
                html += '<td colspan="6">';


                html += '<table class="table table-bordered table-hover">';
                html +=     '<thead>';
                html +=         '<tr>';
                html +=             '<th>Servicio</th>';
                html +=             '<th>Matricula</th>';
                html +=             '<th>Fecha</th>';
                html +=             '<th>Escoba</th>';
                html +=             '<th>Fallido</th>';
                html +=             '<th>Kms.</th>';
                html +=             '<th>Importe</th>';
                html +=         '</tr>'
                html +=     '</thead>';
                html +=     '<tbody>';

                if ( services_standar.length > 0 ) {
                    for ( let s1=0; s1 < services_standar.length; s1++ ) {
                        html +=         '<tr>';
                        html +=             '<td>'+services_standar[s1].service+'</td>';
                        html +=             '<td>'+services_standar[s1].numberplate+'</td>';
                        html +=             '<td>'+services_standar[s1].date+'</td>';
                        if (services_standar[s1].broom != null) {
                            html +=             '<td>'+services_standar[s1].broom+'</td>';
                        } else {
                            html +=             '<td>No</td>';
                        }
                        if (services_standar[s1].failed) {
                            html +=             '<td>Sí</td>';
                        } else {
                            html +=             '<td>No</td>';
                        }
                        html +=             '<td>'+services_standar[s1].kms+'</td>';
                        html +=             '<td>'+services_standar[s1].cost+'</td>';
                        html +=         '</tr>';
                    }
                }

                if ( services_itv.length > 0 ) {
                    for ( let s2=0; s2 < services_itv.length; s2++ ) {
                        html +=         '<tr>';
                        html +=             '<td>'+services_itv[s2].service+'</td>';
                        html +=             '<td>'+services_itv[s2].numberplate+'</td>';
                        html +=             '<td>'+services_itv[s2].date+'</td>';
                        if (services_itv[s2].broom != null) {
                            html +=             '<td>'+services_itv[s2].broom+'</td>';
                        } else {
                            html +=             '<td>No</td>';
                        }
                        if (services_itv[s2].failed) {
                            html +=             '<td>Sí</td>';
                        } else {
                            html +=             '<td>No</td>';
                        }
                        html +=             '<td>'+services_itv[s2].kms+'</td>';
                        html +=             '<td>'+services_itv[s2].cost+'</td>';
                        html +=         '</tr>';
                    }
                }

                if ( services_transit.length > 0 ) {
                    for ( let s3=0; s3 < services_transit.length; s3++ ) {
                        html +=         '<tr>';
                        html +=             '<td>'+services_transit[s3].service+'</td>';
                        html +=             '<td>'+services_transit[s3].numberplate+'</td>';
                        html +=             '<td>'+services_transit[s3].date+'</td>';
                        if (services_transit[s3].broom != null) {
                            html +=             '<td>'+services_transit[s3].broom+'</td>';
                        } else {
                            html +=             '<td>No</td>';
                        }
                        if (services_transit[s3].failed) {
                            html +=             '<td>Sí</td>';
                        } else {
                            html +=             '<td>No</td>';
                        }
                        html +=             '<td>'+services_transit[s3].kms+'</td>';
                        html +=             '<td>'+services_transit[s3].cost+'</td>';
                        html +=         '</tr>';
                    }
                }

                html +=     '</tbody>';
                html += '</table>';


                html += '</td>';
                html += '</tr>';
            }

            $("#process-freelance-list tbody").html(html);
            $("#process-response-freelance").fadeIn(300);

            $("#channel-cable").fadeOut(500);
            $("#channel-cable-h1").text('');
            $("#channel-cable-p").text('');
            setTimeout( () => {
                spin_out();
            }, 300 );

        }

    })
    .catch((error) => {
        $("#channel-cable").hide();
        $("#channel-cable-h1").text('');
        $("#channel-cable-p").text('');
        spin_out();
        error_msg(error);
    })
}

window.process_show_driver_details = (driver_id) => {

    const auth_token = localStorage.getItem('auth_token');
    const values = {"locale": $("#locale").val(), "authenticity_token": $("#authenticity_token").val(), "driver_id": driver_id }

    spin_in();

    fetch('/'+values.locale+'/process_drivers/api/v1/process_show_driver_details',
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(values),
        },
        10000
    )

    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log(data);
        spin_out();
        if ( data.status == 401 ) {
            toast_msg(data.error_msg, 'warning');
        } else {

            $(".process-show-driver-info").hide();

            const services = data.services;

            let html = '';
            //html += '<h3>' + data.driver_name + '</h3>';
            html += '<table class="table table-sm">';
            html +=     '<thead>';
            html +=         '<tr>';
            html +=             '<th scope="col">Servicio</th>';
            html +=             '<th scope="col">Matricula</th>';
            html +=             '<th scope="col">Recogida</th>';
            html +=             '<th scope="col">Entrega</th>';
            html +=         '</tr>';
            html +=     '</thead>';
            html +=     '<tbody>';
            for ( let i=0; i<services.length; i++ ) {
                
                html += '<tr class="table-dark">';
                html +=     '<th>Día: ' + services[i].date + '</th>';
                html +=     '<th>Horas: ' + services[i].work_time + '</th>';
                html +=     '<th>Comienza: ' + services[i].worker_start + '</th>';
                html +=     '<th>Termina: ' + services[i].worker_end + '</th>';
                html += '</tr>';

                const days = services[i].day_services_list;
                for ( let j=0; j<days.length; j++ ) {
                    let text_color = '';
                    if ( days[j].is_extra ) {
                        text_color = ' style="color: red;"';
                    }
                    html += '<tr' + text_color + '>';
                    html +=     '<td>' + days[j].service + '</td>';
                    html +=     '<td class="text-center">' + days[j].numberplate + '</td>';
                    html +=     '<td class="text-center">' + days[j].pickup + '</td>';
                    html +=     '<td class="text-center">' + days[j].delivery + '</td>';
                    html += '</tr>';
                }

            }
            html +=     '</tbody>';
            html += '</table>';


            $("#process-details-days-"+driver_id+" td").html(html);
            $("#process-details-days-"+driver_id).show();

        }

    })
    .catch((error) => {
        spin_out();
        error_msg(error);
    })
}

window.process_upload_freelance_variables = (driver_id) => {
    const auth_token = localStorage.getItem('auth_token');
    const locale = $("#locale").val();
    const values = { "locale": locale, "authenticity_token": $("#authenticity_token").val(), "driver_id": driver_id, "year": $("#file-year").val(), "month": $("#file-month").val(), "variable_text": $("#variable_text_"+driver_id).val(), "variable_amount": $("#variable_amount_"+driver_id).val() }

    fetch('/'+locale+'/process_drivers/api/v1/process_upload_freelance_variables',
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(values),
        },
        10000
    )

    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log(data);
        spin_out();
        if ( data.status == 401 ) {
            toast_msg(data.error_msg, 'warning');
            $("#variable_text_"+driver_id).css( 'background-color', 'rgb(255, 120, 120)' );
            $("#variable_amount_"+driver_id).css( 'background-color', 'rgb(255, 120, 120)' );
        } else {
            $("#variable_text_"+driver_id).css( 'background-color', 'rgb(200, 255, 159)' );
            $("#variable_amount_"+driver_id).css( 'background-color', 'rgb(200, 255, 160)' );
            const html = '<a href="javascript:process_delete_freelance_variables(\'' + data.variable.id + '\');"><i class="fas fa-times-circle fa-2x"></i></a>'
            $("#variable_btn_wrapper_"+driver_id).html( html );
        }

    })
    .catch((error) => {
        error_msg(error);
    })
}

window.process_delete_freelance_variables = (variable_id) => {
    const auth_token = localStorage.getItem('auth_token');
    const locale = $("#locale").val();
    const values = { "locale": locale, "authenticity_token": $("#authenticity_token").val(), "variable_id": variable_id, "year": $("#file-year").val(), "month": $("#file-month").val() }

    fetch('/'+locale+'/process_drivers/api/v1/process_delete_freelance_variables',
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(values),
        },
        10000
    )

    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log(data);
        spin_out();
        if ( data.status == 401 ) {
            toast_msg(data.error_msg, 'warning');
        } else {
            const driver_id = data.driver_id
            $("#variable_text_"+driver_id).css( 'background-color', 'transparent' );
            $("#variable_amount_"+driver_id).css( 'background-color', 'transparent' );
            $("#variable_text_"+driver_id).val( '' );
            $("#variable_amount_"+driver_id).val( '' );
            const html = '<a href="javascript:process_upload_freelance_variables(\'' + driver_id + '\');"><i class="fas fa-upload fa-2x"></i></a>'
            $("#variable_btn_wrapper_"+driver_id).html( html );
        }

    })
    .catch((error) => {
        error_msg(error);
    })
}

window.process_freelance_drivers_send_mail = (auth_token, values) => {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    $("#process-response-zone").hide();

    spin_in();
    $("#channel-cable-progress-bar").css( 'width', '0%' );
    $("#channel-cable").fadeIn(500);

    fetch('/'+values.locale+'/process_drivers/api/v1/process_freelance_drivers_send_mail',
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(values),
        },
        10000
    )

    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log(data);
        
        $("#channel-cable").fadeOut(500);
        $("#channel-cable-h1").text('');
        $("#channel-cable-p").text('');
        setTimeout( () => {
            spin_out();
        }, 300 );

        let mails_list = '';
        let names = data.freelance_summary;
        for ( let i = 0; i < names.length; i++ ) {
            mails_list += '<li>' + names[i].driver_name + '</li>';
        }

        Swal.fire({
            title: '<h2>' + names.length + ' Emails enviados</h2>',
            icon: 'info',
            html:'<p>Se ha enviado email a los siguientes conductores:</p><ul style="text-align: left;">' + mails_list + '</ul>',
            showCloseButton: true
        });

    })
    .catch((error) => {
        $("#channel-cable").hide();
        $("#channel-cable-h1").text('');
        $("#channel-cable-p").text('');
        spin_out();
        error_msg(error);
    })
}

window.open_freelance_details = (driver_id) => {
    $("#process-freelance-details-"+driver_id).toggle();
}

window.construct_mail_freelance_object = (ids) => {
    let new_arr = [];
    for ( let i = 0; i < ids.length; i++ ) {
        let id = parseInt(ids[i]);

        let obj = {
            "id": id,
            "text": $("#variable_text_"+id.toString()).val(),
            "amount": $("#variable_amount_"+id.toString()).val()
        }
        
        new_arr.push(obj);
    }
    return new_arr;
}

window.process_download_invoces_resume = (auth_token, values) => {
    // info from https://www.alexhadik.com/writing/xhr-file-download/
    console.log("VALUES", values);
    const url = "/es/process_drivers/api/v1/process_download_invoces_resume";
    console.log("URL", url);

    //e.preventDefault();
    let xhr = new XMLHttpRequest();
    //set the request type to post and the destination url to '/convert'
    xhr.open('POST', url);
    //set headers
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", "Bearer "+auth_token);
    //set the reponse type to blob since that's what we're expecting back
    xhr.responseType = 'blob';
    let postData = JSON.stringify(values);
    xhr.send(postData); 
  
    xhr.onload = function(e) {
        if (this.status == 200) {
            // let contentDispo = e.currentTarget.getResponseHeader('Content-Disposition');
            // console.log(contentDispo);
            // let fileName = contentDispo.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1];
            //let fileName = xhr.getResponseHeader('Content-Disposition').split("filename=")[1];
            let fileName = values.year.toString() + "_invoices.xlsx"
            console.log(fileName);
            // Create a new Blob object using the response data of the onload object
            var blob = new Blob([this.response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            //Create a link element, hide it, direct it towards the blob, and then 'click' it programatically
            let a = document.createElement("a");
            a.style = "display: none";
            document.body.appendChild(a);
            //Create a DOMString representing the blob and point the link element towards it
            let url = window.URL.createObjectURL(blob);
            a.href = url;
            //a.download = 'myFile.pdf';
            a.download = fileName;
            //programatically click the link to trigger the download
            a.click();
            //release the reference to the file by revoking the Object URL
            window.URL.revokeObjectURL(url);
        }else{
            //deal with your error state here
            console.log("ERROR en el else")
        }
    };
}

window.process_download_statistics_resume = (auth_token, values) => {
    console.log("VALUES", values);
    const url = "/es/process_drivers/api/v1/process_download_statistics_resume";
    console.log("URL", url);

    let xhr = new XMLHttpRequest();
    //set the request type to post and the destination url to '/convert'
    xhr.open('POST', url);
    //set headers
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", "Bearer "+auth_token);
    //set the reponse type to blob since that's what we're expecting back
    xhr.responseType = 'blob';
    let postData = JSON.stringify(values);
    xhr.send(postData);

    xhr.onload = function(e) {
        if (this.status == 200) {
            // let contentDispo = e.currentTarget.getResponseHeader('Content-Disposition');
            // console.log(contentDispo);
            // let fileName = contentDispo.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1];
            //let fileName = xhr.getResponseHeader('Content-Disposition').split("filename=")[1];
            let fileName = values.year.toString() + "_statistics.xlsx"
            console.log(fileName);
            // Create a new Blob object using the response data of the onload object
            var blob = new Blob([this.response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            //Create a link element, hide it, direct it towards the blob, and then 'click' it programatically
            let a = document.createElement("a");
            a.style = "display: none";
            document.body.appendChild(a);
            //Create a DOMString representing the blob and point the link element towards it
            let url = window.URL.createObjectURL(blob);
            a.href = url;
            //a.download = 'myFile.pdf';
            a.download = fileName;
            //programatically click the link to trigger the download
            a.click();
            //release the reference to the file by revoking the Object URL
            window.URL.revokeObjectURL(url);
        }else{
            //deal with your error state here
            console.log("ERROR en el else")
        }
    };
}














window.use_last_uploaded_file = (auth_token, values) => {

    fetch('/'+values.locale+'/process_drivers/api/v1/use_last_uploaded_file',
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(values),
        },
        10000
    )

    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log(data);

        $("#file-year").val(data.year);
        $("#file-month").val(data.month);

        $("#process-response-zone-ok").fadeIn(300);

    })
    .catch((error) => {
        $("#channel-cable").hide();
        $("#channel-cable-h1").text('');
        $("#channel-cable-p").text('');
        spin_out();
        error_msg(error);
    })
}






