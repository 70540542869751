// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
//import "bootstrap"

import 'bootstrap/dist/js/bootstrap'
import "bootstrap/dist/css/bootstrap";

import "jquery-validation"
import "jquery-validation/dist/additional-methods"

import "packs/engines_browser"
import "packs/helpers"
import "packs/fetch_with_timeout"

import "packs/engines/lihtconfigs/lihtconfigs"
import "packs/engines/lihtlogin/lihtlogin"
import "packs/engines/lihtusers/lihtusers"
import "packs/engines/drivers/drivers"
import "packs/engines/process_drivers/process_drivers"


// document.addEventListener("turbolinks:load", () => {
//     $('[data-toggle="tooltip"]').tooltip()
// });



Rails.start()
Turbolinks.start()
ActiveStorage.start()

/* Application scss */
import '../stylesheets/main.scss'

require('admin-lte')

/* Font awesome */
import '@fortawesome/fontawesome-free/css/all'

/* Sweetalert2 */
import Swal from "sweetalert2"
window.Swal = Swal;

// Spinner css from https://www.cssscript.com/loading-spinner-toast-noticekit/
//import '@ouduidui/notice/style.css';

