// lihtusers.js

import { event } from 'jquery';
import fetch from '../../fetch_with_timeout'
import { 
    lihtlogin_langs_recover_password,
    lihtusers_langs_end_of_load,
    lihtusers_menu_labels,
    global_jquery_validate_langs,
    lihtusers_avatar_data,
    lihtusers_delete_user_alert_data
} from '../../languages'

$(function() {

    const auth_token = localStorage.getItem('auth_token');
    const locale = $("#locale").val();
    const values = {"locale": $("#locale").val(), "authenticity_token": $("#authenticity_token").val(), "start_load": 0}

    // Browsing between actions
    $(".lihtusers-list-users").on( 'click', (event) => {
        event.preventDefault();
        lihtusers_refresh_form();

        $(".lihtusers-list-users").addClass('active');
        $(".lihtusers-new-user").removeClass('active');
        hide_show('new-user-layer','list-user-layer',200);

        // Delete present and reload first users page
        $("#lihtusers-users-list tbody tr").remove();
        lihtusers_load_users_list_action(auth_token, values);
    });

    $(".lihtusers-new-user").on( 'click', (event) => {
        event.preventDefault();
        lihtusers_refresh_form();
        
        $(".lihtusers-new-user").addClass('active');
        $(".lihtusers-list-users").removeClass('active');
        hide_show('list-user-layer','new-user-layer',200);
    });

    // Init pages loading all users
    if ( window.location.pathname.includes('/lihtusers/')  ) {
        lihtusers_load_users_list_action(auth_token, values);
    }

    $(window).on( 'scroll', function() {
        if ( $(".list-user-layer").is(":visible") ) {
            if($(window).scrollTop() == $(document).height() - $(window).height()) {
                // ajax call get data from server and append to the div
                const values = {"locale": $("#locale").val(), "authenticity_token": $("#authenticity_token").val(), "start_load": $("#lihtusers-users-list").attr('start-load') }
                console.log("Cargando...");
                lihtusers_load_users_list_action(auth_token, values);
            }
        }
    });

    // Form Validation
    $.extend($.validator.messages, global_jquery_validate_langs[locale] );

    // Send crate new user
    $(".btn-create-new-user").on( 'click', (event) =>  {
        event.preventDefault();

        $('#new-user-form').validate({
            rules: {
                name_surname: {
                    required: true
                },
                email: {
                    required: true,
                    email: true,
                    remote: '/lihtusers/api/v1/check_email'
                },
                password: {
                    required: true,
                    minlength: 8
                },
                password_confirm: {
                    required: true,
                    minlength: 8,
                    equalTo: "#password"
                }
            
            },
            messages: {
                // email: {
                //     required: "Please enter a email address",
                //     email: "Please enter a vaild email address"
                // },
                // password: {
                //     required: "Please provide a password",
                //     minlength: "Your password must be at least 5 characters long"
                // },
            },
            errorElement: 'span',
            errorPlacement: function (error, element) {
                error.addClass('invalid-feedback');
                element.closest('.form-group').append(error);
            },
            highlight: function (element, errorClass, validClass) {
                $(element).addClass('is-invalid');
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass('is-invalid');
            }
        });

        const user_avatar = document.querySelector('#user-avatar')
        let values = new FormData();
        values.append("locale", locale);
        values.append("authenticity_token", $("#authenticity_token").val());
        values.append("name", $("#name-surname").val());
        values.append("email", $("#email").val());
        values.append("password", $("#password").val());
        values.append("app_id", $("#app_id option:selected").val());
        values.append("inherit_id", $("#inherit_id option:selected").val());
        if ( $("#is_actived").prop('checked') ) {
            values.append("is_actived", true);
        } else {
            values.append("is_actived", false);
        }
        if ( $("#newsletter").prop('checked') ) {
            values.append("newsletter", true);
        } else {
            values.append("newsletter", false);
        }

        // Apps
        let apps=[];
        $('.user-apps:checked').each(function() {
            apps.push(parseInt($(this).val()));
        });
        values.append("apps", apps);

        // Groups
        let groups=[];
        $('.user-groups:checked').each(function() {
            groups.push(parseInt($(this).val()));
        });
        values.append("groups", groups);

        values.append("avatar", user_avatar.files[0]);

        if ( $("#new-user-form").valid() && lihtusers_check_strong_password(locale, $("#password").val()) ) {
            lihtusers_create_new_user_action(auth_token, values);
        }
    });

    $("#user-avatar").on( 'change', () =>  {
        let oFReader = new FileReader();
        oFReader.readAsDataURL(document.getElementById("user-avatar").files[0]);
        oFReader.fileName = document.getElementById("user-avatar").files[0];
        oFReader.onload = function (oFREvent) {
            document.getElementById("upload-preview").src = oFREvent.target.result;
            
            console.log( oFREvent.target.fileName );
            $("#user-avatar").next('.custom-file-label').html(oFREvent.target.fileName.name);
            $("#lihtusers-file-info").html(lihtusers_avatar_data[locale].size + ": " + oFREvent.target.fileName.size + " - " + lihtusers_avatar_data[locale].type + ": " + oFREvent.target.fileName.type )

        };

    });

    $(".btn-delete-image-user").on( 'click', () => {
        const values = { "locale": $("#locale").val(), "authenticity_token": $("#authenticity_token").val(), "user_id": $("#edit_user_id").val() };
        lihtusers_delete_user_image_action(auth_token, values);
    });

    $(".btn-edit-user").on( 'click', (event) =>  {
        event.preventDefault();

        $('#edit-user-form').validate({
            rules: {
                name_surname: {
                    required: true
                },
                password: {
                    //required: false,
                    minlength: 8
                },
                password_confirm: {
                    //required: false,
                    minlength: 8,
                    equalTo: "#password"
                }
            },
            messages: {
                // email: {
                //     required: "Please enter a email address",
                //     email: "Please enter a vaild email address"
                // },
                // password: {
                //     required: "Please provide a password",
                //     minlength: "Your password must be at least 5 characters long"
                // },
            },
            errorElement: 'span',
            errorPlacement: function (error, element) {
                error.addClass('invalid-feedback');
                element.closest('.form-group').append(error);
            },
            highlight: function (element, errorClass, validClass) {
                $(element).addClass('is-invalid');
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass('is-invalid');
            }
        });

        const user_avatar = document.querySelector('#user-avatar')
        let values = new FormData();
        values.append("locale", locale);
        values.append("authenticity_token", $("#authenticity_token").val());
        values.append("name", $("#name-surname").val());
        values.append("user_id", $("#edit_user_id").val());
        values.append("password", $("#password").val());
        values.append("app_id", $("#app_id option:selected").val());
        values.append("inherit_id", $("#inherit_id option:selected").val());
        if ( $("#is_actived").prop('checked') ) {
            values.append("is_actived", true);
        } else {
            values.append("is_actived", false);
        }
        if ( $("#newsletter").prop('checked') ) {
            values.append("newsletter", true);
        } else {
            values.append("newsletter", false);
        }

        // Apps
        let apps=[];
        $('.user-apps:checked').each(function() {
            apps.push(parseInt($(this).val()));
        });
        values.append("apps", apps);

        // Groups
        let groups=[];
        $('.user-groups:checked').each(function() {
            groups.push(parseInt($(this).val()));
        });
        values.append("groups", groups);

        if ( $("#user-avatar").val() != "" ) {
            values.append("avatar", user_avatar.files[0]);
        }
        

        if ( $("#edit-user-form").valid() && lihtusers_check_strong_password(locale, $("#password").val()) ) {
            lihtusers_edit_user_action(auth_token, values);
        }
    });

    // $("#header-search").on( 'keyup', (event) => {
    //     const search_length = $("#header-search").val().length;
    //     if ( search_length > 3 ) {
    //         const values = {"locale": $("#locale").val(), "authenticity_token": $("#authenticity_token").val(), "query": $("#header-search").val(), "show_info": true };
    //         lihtusers_live_search_action(auth_token, values);
    //     } else {
    //         const values = {"locale": $("#locale").val(), "authenticity_token": $("#authenticity_token").val(), "query": "", "show_info": false };
    //         lihtusers_live_search_action(auth_token, values);
    //     }
    // });


});

window.lihtusers_check_strong_password = (locale, password) => {
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
    if( password != "" ) {
        if (strongRegex.test(password)){
            $("#password-error").text('').hide();
            return true;
        } else {
            $("#password-error").text(lihtlogin_langs_recover_password[locale].regex.body).show();
            return false;
        }
    } else {
        return true;
    }
}

window.lihtusers_refresh_form = () => {
    $("#name-surname").val('');
    $("#email").val('').prop( 'disabled', false );
    $("#password").val('');
    $("#password_confirm").val('');
    $("#app_id option:eq(1)");
    $("#inherit_id option:eq(1)");
    $("#is_actived").prop( 'checked', true );
    $("#newsletter").prop( 'checked', true );
    $("#user-avatar").next('.custom-file-label').text('');
    $(".btn-delete-image-user").hide();

    $("#edit-user-form").attr( 'id', 'new-user-form' );
    $(".btn-create-new-user").show();
    $(".btn-edit-user").hide();
    $(".lihtusers-edit-info-show").hide();
    $("#upload-preview").removeAttr('src');
    $("#lihtusers-file-info").text('');
}

window.lihtusers_load_users_list_action = (auth_token, values) => {
    spin_in();
    fetch('/'+values.locale+'/lihtusers/api/v1/load_users_list',
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(values),
        },
        10000
    )
    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log(data);
        spin_out();

        $("#lihtusers-users-list").attr('start-load', data.start_load)

        let users = data.users;

        let html = '';
        for ( let i=0; i<users.length; i++ ) {
            html += '<tr id="user-'+users[i].id+'">';
            if( users[i].avatar ){
                html +=     '<td><img src="'+users[i].avatar+'" class="img-circle elevation-2" style="max-width: 2.5rem;" /></td>';
            } else {
                html +=     '<td></td>';
            }
            html +=     '<td>';
            html +=         '<div class="btn-group">';
            html +=             '<a href="#" class="fas fa-ellipsis-v" data-bs-toggle="dropdown" aria-expanded="false">';
            html +=                 '<span class="visually-hidden">Toggle Dropdown</span>';
            html +=             '</a>';
            html +=             '<ul class="dropdown-menu">';
            html +=                 '<li><a class="dropdown-item" href="javascript:lihtusers_edit_user_load(\''+users[i].id+'\');">'+lihtusers_menu_labels[values.locale].edit_menu.edit+'</a></li>';
            html +=                 '<li><a class="dropdown-item" href="javascript:lihtusers_delete_user_action(\''+users[i].id+'\',\''+users[i].name+'\');">'+lihtusers_menu_labels[values.locale].edit_menu.delete+'</a></li>';
            html +=             '</ul>';
            html +=         '</div>';
            html +=     '</td>';
            html +=     '<td>' + users[i].name + '</td>';
            html +=     '<td><a href="mailto:' + users[i].email + '">' + users[i].email + '</a></td>';
            html +=     '<td>' + lihtusers_print_bootstrap_switch_from_true_false(users[i].is_actived, users[i].id, 'ac' ) + '</td>';
            html +=     '<td>' + lihtusers_print_bootstrap_switch_from_true_false(users[i].newsletter, users[i].id, 'nl' ) + '</td>';
            html += '</tr>';
        }

        $("#lihtusers-users-list tbody").append(html);


        if ( data.is_finished && $(".list-user-layer").is(":visible") ) {
            $(document).Toasts('create', {
                class: 'bg-success',
                position: 'bottomRight',
                icon: "fas fa-info-circle",
                close: false,
                autohide: true,
                delay: 3000,
                title: lihtusers_langs_end_of_load[values.locale].is_finished.title,
                body: lihtusers_langs_end_of_load[values.locale].is_finished.body+' ('+data.users_total+')'
            });
        }

    })
    .catch((error) => {
        spin_out();
        error_msg('Problema de conexión con el servidor', 'Error code: E001', 'Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
    })
}

window.lihtusers_print_bootstrap_switch_from_true_false = (val, id, field) => {

    if (val) {
        return '<div class="form-check form-switch form-switch-md text-center"><input class="form-check-input" type="checkbox" checked onchange="lihtusers_boolean_change_action(\''+id+'\', \''+field+'\');">';
    } else {
        return '<div class="form-check form-switch form-switch-md text-center"><input class="form-check-input" type="checkbox" onchange="lihtusers_boolean_change_action(\''+id+'\', \''+field+'\');">';
    }

}

window.lihtusers_create_new_user_action = (auth_token, values) => {
    console.log(values);
    spin_in();
    fetch('/'+values.get("locale")+'/lihtusers/api/v1/create_new_user',
        {
            method: "post",
            headers: {
                //"Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: values,
        },
        10000
    )
    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log(data);
        spin_out();
        if ( data.status == 401 ) {
            toast_msg(data.error_msg, 'warning');
        } else {
            lihtusers_refresh_form();

            $(".lihtusers-list-users").addClass('active');
            $(".lihtusers-new-user").removeClass('active');
            hide_show('new-user-layer','list-user-layer',200);
    
            // Delete present and reload first users page
            $("#lihtusers-users-list tbody tr").remove();
            values = {"locale": $("#locale").val(), "authenticity_token": $("#authenticity_token").val(), "start_load": 0}
            lihtusers_load_users_list_action(auth_token, values);

            toast_msg(data.ok_msg, 'success');
        }

    })
    .catch((error) => {
        spin_out();
        error_msg('Problema de conexión con el servidor', 'Error code: E001', 'Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
    })
}

window.lihtusers_delete_user_action = (user_id, user_name) => {
    const auth_token = localStorage.getItem('auth_token');
    const values = { "locale": $("#locale").val(), "authenticity_token": $("#authenticity_token").val(), "user_id": user_id, "user_name": user_name }
    console.log(values);  
    Swal.fire({
        title: lihtusers_delete_user_alert_data[values.locale].title,
        text: user_name + ' ' + lihtusers_delete_user_alert_data[values.locale].text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: lihtusers_delete_user_alert_data[values.locale].btn_delete,
        cancelButtonText: lihtusers_delete_user_alert_data[values.locale].btn_cancel
    }).then((result) => {
        if (result.isConfirmed) {
            spin_in();
            fetch('/'+values.locale+'/lihtusers/api/v1/delete_user',
                {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `bearer ${auth_token}`,
                    },
                    body: JSON.stringify(values),
                },
                10000
            )
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                console.log(data);
                spin_out();
                if ( data.status == 401 ) {
                    Swal.fire(
                        data.error_title,
                        data.error_msg,
                        'error'
                    )
                } else if ( data.status == 200 ) {
                    toast_msg(data.ok_msg, 'success')
                    $('#user-'+values.user_id).fadeOut(200);
                    setTimeout(() => {
                        $('#user-'+values.user_id).remove();
                    },200 )
                }

            })
            .catch((error) => {
                spin_out();
                error_msg('Problema de conexión con el servidor', 'Error code: E001', 'Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
            })
        }
    })

}

window.lihtusers_boolean_change_action = (user_id, field) => {
    const auth_token = localStorage.getItem('auth_token');
    const values = { "locale": $("#locale").val(), "authenticity_token": $("#authenticity_token").val(), "user_id": user_id, "field": field }

    spin_in();
    fetch('/'+values.locale+'/lihtusers/api/v1/modify_booleans',
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(values),
        },
        10000
    )
    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log(data);
        spin_out();
        if ( data.status == 401 ) {
            toast_msg(data.error_msg, 'error');
        } else if ( data.status == 200 ) {
            toast_msg(data.ok_msg, 'success');
        }

    })
    .catch((error) => {
        spin_out();
        error_msg('Problema de conexión con el servidor', 'Error code: E001', 'Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
    })

}

window.lihtusers_edit_user_load = (user_id) => {
    const auth_token = localStorage.getItem('auth_token');
    const values = { "locale": $("#locale").val(), "authenticity_token": $("#authenticity_token").val(), "user_id": user_id }
    spin_in();
    fetch('/'+values.locale+'/lihtusers/api/v1/request_user_data_to_edit',
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(values),
        },
        10000
    )
    .then((response) => {
        return response.json()
    })
    .then((data) => {
        spin_out();
        console.log(data);

        if ( data.status == 401 ) {
            toast_msg(data.error_msg, 'error');
        } else if ( data.status == 200 ) {

            // Load data in form
            const user = data.user;

            $("#name-surname").val(user.name);
            $("#email").val(user.email).prop( 'disabled', true );
            $("#password").val('');
            $("#password_confirm").val('');
            $("#app_id").val(user.app_id);
            $("#inherit_id").val(user.inherit_id);
            if ( user.is_actived ) {
                $("#is_actived").prop( 'checked', true );
            } else {
                $("#is_actived").prop( 'checked', false );
            }
            if ( user.newsletter ) {
                $("#newsletter").prop( 'checked', true );
            } else {
                $("#newsletter").prop( 'checked', false );
            }

            // Apps
            let apps=user.apps.split(',');
            for (let i=0; i<apps.length; i++){
                $("#app-"+apps[i]).prop( 'checked', true );
            }

            // Apps
            let groups=user.groups.split(',');
            for (let i=0; i<groups.length; i++){
                $("#group-"+groups[i]).prop( 'checked', true );
            }

            if (!data.avatar){
                $(".btn-delete-image-user").hide();
                $("#upload-preview").removeAttr('src');
            } else {
                $("#upload-preview").prop( 'src', data.avatar );
                $(".btn-delete-image-user").show();
            }
            $("#user-avatar").next('.custom-file-label').text('');
            $("#edit_user_id").val(user.id);
            

            $("#new-user-form").attr( 'id', 'edit-user-form' );
            $(".btn-create-new-user").hide();
            $(".btn-edit-user").show();
            $(".lihtusers-edit-info-show").show();

            $(".lihtusers-new-user").addClass('active');
            $(".lihtusers-list-users").removeClass('active');
            hide_show('list-user-layer','new-user-layer',200);
        }

    })
    .catch((error) => {
        spin_out();
        error_msg(error);
        //error_msg('Problema de conexión con el servidor', 'Error code: E001', 'Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
    })
}

window.lihtusers_delete_user_image_action = (auth_token, values) => {
    spin_in();
    fetch('/'+values.locale+'/lihtusers/api/v1/delete_user_image',
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(values),
        },
        10000
    )
    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log(data);
        spin_out();
        if ( data.status == 401 ) {
            toast_msg(data.error_msg, 'error');
        } else if ( data.status == 200 ) {
            toast_msg(data.ok_msg, 'success');
            $("#upload-preview").removeAttr('src');
            $(".btn-delete-image-user").hide();
        }

    })
    .catch((error) => {
        spin_out();
        error_msg('Problema de conexión con el servidor', 'Error code: E001', 'Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
    })
}

window.lihtusers_edit_user_action = (auth_token, values) => {
    console.log(values);
    spin_in();
    fetch('/'+values.get("locale")+'/lihtusers/api/v1/edit_user',
        {
            method: "post",
            headers: {
                //"Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: values,
        },
        10000
    )
    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log(data);
        spin_out();
        if ( data.status == 401 ) {
            toast_msg(data.error_msg, 'warning');
        } else {
            lihtusers_refresh_form();

            $(".lihtusers-list-users").addClass('active');
            $(".lihtusers-new-user").removeClass('active');
            hide_show('new-user-layer','list-user-layer',200);
    
            // Delete present and reload first users page
            $("#lihtusers-users-list tbody tr").remove();
            values = {"locale": $("#locale").val(), "authenticity_token": $("#authenticity_token").val(), "start_load": 0}
            lihtusers_load_users_list_action(auth_token, values);

            toast_msg(data.ok_msg, 'success');
        }

    })
    .catch((error) => {
        spin_out();
        error_msg('Problema de conexión con el servidor', 'Error code: E001', 'Envíe este correo manteniendo el código de error que aparece en el asunto para que podamos revisarlo. Si lo desea puede añadir aqui algún detalle más que pueda ayudarnos a encontrar el problema.');
    })
}

window.lihtusers_live_search_action = (auth_token, values) => {
    lihtusers_refresh_form();

    $(".lihtusers-list-users").addClass('active');
    $(".lihtusers-new-user").removeClass('active');
    hide_show('new-user-layer','list-user-layer',200);
    

    fetch('/'+values.locale+'/lihtusers/api/v1/live_search',
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(values),
        },
        10000
    )
    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log(data);
        //spin_out();

        $("#lihtusers-users-list tbody").empty();

        let users = data.users;

        let html = '';
        for ( let i=0; i<users.length; i++ ) {
            html += '<tr id="user-'+users[i].id+'">';
            html +=     '<td><img src="'+users[i].avatar+'" class="img-circle elevation-2" style="max-width: 2.5rem;" /></td>';
            html +=     '<td>';
            html +=         '<div class="btn-group">';
            html +=             '<a href="#" class="fas fa-ellipsis-v" data-bs-toggle="dropdown" aria-expanded="false">';
            html +=                 '<span class="visually-hidden">Toggle Dropdown</span>';
            html +=             '</a>';
            html +=             '<ul class="dropdown-menu">';
            html +=                 '<li><a class="dropdown-item" href="javascript:lihtusers_edit_user_load(\''+users[i].id+'\');">'+lihtusers_menu_labels[values.locale].edit_menu.edit+'</a></li>';
            html +=                 '<li><a class="dropdown-item" href="javascript:lihtusers_delete_user_action(\''+users[i].id+'\',\''+users[i].name+'\');">'+lihtusers_menu_labels[values.locale].edit_menu.delete+'</a></li>';
            html +=             '</ul>';
            html +=         '</div>';
            html +=     '</td>';
            html +=     '<td>' + users[i].name + '</td>';
            html +=     '<td><a href="mailto:' + users[i].email + '">' + users[i].email + '</a></td>';
            html +=     '<td>' + lihtusers_print_bootstrap_switch_from_true_false(users[i].is_actived, users[i].id, 'ac' ) + '</td>';
            html +=     '<td>' + lihtusers_print_bootstrap_switch_from_true_false(users[i].newsletter, users[i].id, 'nl' ) + '</td>';
            html += '</tr>';
        }

        $("#lihtusers-users-list tbody").append(html);

        if ( values.show_info ) {
            $(".search-mode").text(data.search_info).show();
        } else {
            $(".search-mode").empty().hide();
        }

    })
    .catch((error) => {
        //spin_out();
        error_msg(error);
    })
}