// drivers.js

import fetch from '../../fetch_with_timeout'

$(function() {

    const auth_token = localStorage.getItem('auth_token');
    const locale = $("#locale").val();

    // Init pages loading all drivers
    if ( window.location.pathname.includes('/drivers/')  ) {
        const values = {"locale": locale, "authenticity_token": $("#authenticity_token").val(), "start_load": 0};
        drivers_refresh_form();
        drivers_load_drivers_list_action(auth_token, values);
    }

    $(window).on( 'scroll', function() {
        if ( $(".list-driver-layer").is(":visible") ) {
            if($(window).scrollTop() == $(document).height() - $(window).height()) {
                // ajax call get data from server and append to the div
                const values = {"locale": $("#locale").val(), "authenticity_token": $("#authenticity_token").val(), "start_load": $("#drivers-drivers-list").attr('start-load') }
                console.log("Cargando...");
                drivers_load_drivers_list_action(auth_token, values);
            }
        }
    });

    $(".drivers-list-drivers").on( 'click', (event) => {
        event.preventDefault();
        drivers_refresh_form();

        $(".drivers-list-drivers").addClass('active');
        $(".drivers-new-driver").removeClass('active');
        hide_show('new-driver-layer','list-driver-layer',200);

        // Delete present and reload first users page
        $("#drivers-drivers-list tbody tr").remove();
        const values = {"locale": locale, "authenticity_token": $("#authenticity_token").val(), "start_load": 0};
        drivers_load_drivers_list_action(auth_token, values);
    });

    $(".drivers-new-driver").on( 'click', (event) => {
        event.preventDefault();
        drivers_refresh_form();
        
        $(".drivers-new-driver").addClass('active');
        $(".drivers-list-drivers").removeClass('active');
        hide_show('list-driver-layer','new-driver-layer',200);
    });

    // Send create new user
    $(".btn-create-new-driver").on( 'click', (event) =>  {
        event.preventDefault();

        $('#new-driver-form').validate({
            rules: {
                id: {
                    required: true,
                    digits: true,
                    remote: '/drivers/api/v1/check_id'
                },
                name: {
                    required: true
                },
                email: {
                    required: true,
                    email: true
                },
                work_time: {
                    required: true,
                    time: true
                },
                excess: {
                    required: true,
                    number: true
                },
                costs: {
                    required: true,
                    number: true
                },
                km_0_15: {
                    required: false,
                    number: true
                },
                km_15_30: {
                    required: false,
                    number: true
                },
                km_30_50: {
                    required: false,
                    number: true
                },
                km_50_80: {
                    required: false,
                    number: true
                },
                km_itv: {
                    required: false,
                    number: true
                },
                km_transit: {
                    required: false,
                    number: true
                },
                km_failure: {
                    required: false,
                    number: true
                },
                km_broom: {
                    required: false,
                    number: true
                },
                km_excess_tp: {
                    required: false,
                    number: true
                },
                km_excess_tp_time: {
                    required: false,
                    digits: true
                },
                invoice_zip: {
                    required: false,
                    digits: true
                },
                invoice_irpf: {
                    required: true,
                    number: true
                }
            
            },
            messages: {
                id: {
                    remote: "Este ID ya existe en el sistema, selecciona otro"
                }
                // email: {
                //     required: "Please enter a email address",
                //     email: "Please enter a vaild email address"
                // },
                // password: {
                //     required: "Please provide a password",
                //     minlength: "Your password must be at least 5 characters long"
                // },
            },
            errorElement: 'span',
            errorPlacement: function (error, element) {
                error.addClass('invalid-feedback');
                element.closest('.form-group').append(error);
            },
            highlight: function (element, errorClass, validClass) {
                $(element).addClass('is-invalid');
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass('is-invalid');
            }
        });

        let values = new FormData();
        values.append("locale", locale);
        values.append("authenticity_token", $("#authenticity_token").val());
        values.append("id", $("#id").val());
        values.append("name", $("#name").val());
        values.append("email", $("#email").val());
        if ( $("#is_freelance").prop('checked') ) {
            values.append("is_freelance", true);
        } else {
            values.append("is_freelance", false);
        }
        if ( $("#is_actived").prop('checked') ) {
            values.append("is_actived", true);
        } else {
            values.append("is_actived", false);
        }
        values.append("work_time", $("#work_time").val());
        values.append("excess", $("#excess").val());
        values.append("costs", $("#costs").val());
        values.append("km_0_15", $("#km_0_15").val());
        values.append("km_15_30", $("#km_15_30").val());
        values.append("km_30_50", $("#km_30_50").val());
        values.append("km_50_80", $("#km_50_80").val());
        values.append("km_itv", $("#km_itv").val());
        values.append("km_transit", $("#km_transit").val());
        values.append("km_failure", $("#km_failure").val());
        values.append("km_broom", $("#km_broom").val());
        values.append("km_excess_tp", $("#km_excess_tp").val());
        values.append("km_excess_tp_time", $("#km_excess_tp_time").val());
        
        values.append("invoice_nif", $("#invoice_nif").val());
        values.append("invoice_name", $("#invoice_name").val());
        values.append("invoice_address", $("#invoice_address").val());
        values.append("invoice_zip", $("#invoice_zip").val());
        values.append("invoice_city", $("#invoice_city").val());
        values.append("invoice_province", $("#invoice_province").val());
        values.append("invoice_irpf", $("#invoice_irpf").val());
        
        if ( $("#new-driver-form").valid() ) {
            drivers_create_new_driver_action(auth_token, values);
        }
    });

    $(".btn-edit-driver").on( 'click', (event) =>  {
        event.preventDefault();

        $('#edit-driver-form').validate({
            rules: {
                id: {
                    required: true,
                    digits: true
                },
                name: {
                    required: true
                },
                email: {
                    required: true,
                    email: true
                },
                work_time: {
                    required: false,
                    time: true
                },
                excess: {
                    required: true,
                    number: true
                },
                costs: {
                    required: true,
                    number: true
                },
                km_0_15: {
                    required: false,
                    number: true
                },
                km_15_30: {
                    required: false,
                    number: true
                },
                km_30_50: {
                    required: false,
                    number: true
                },
                km_50_80: {
                    required: false,
                    number: true
                },
                km_itv: {
                    required: false,
                    number: true
                },
                km_transit: {
                    required: false,
                    number: true
                },
                km_failure: {
                    required: false,
                    number: true
                },
                km_broom: {
                    required: false,
                    number: true
                },
                km_excess_tp: {
                    required: false,
                    number: true
                },
                km_excess_tp_time: {
                    required: false,
                    digits: true
                },
                invoice_zip: {
                    required: false,
                    digits: true
                },
                invoice_irpf: {
                    required: true,
                    number: true
                }

            },
            messages: {
                // email: {
                //     required: "Please enter a email address",
                //     email: "Please enter a vaild email address"
                // },
                // password: {
                //     required: "Please provide a password",
                //     minlength: "Your password must be at least 5 characters long"
                // },
            },
            errorElement: 'span',
            errorPlacement: function (error, element) {
                error.addClass('invalid-feedback');
                element.closest('.form-group').append(error);
            },
            highlight: function (element, errorClass, validClass) {
                $(element).addClass('is-invalid');
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass('is-invalid');
            }
        });

        let values = new FormData();
        values.append("locale", locale);
        values.append("authenticity_token", $("#authenticity_token").val());
        values.append("previous_id", $("#previous_id").val());
        values.append("id", $("#id").val());
        values.append("name", $("#name").val());
        values.append("email", $("#email").val());
        if ( $("#is_freelance").prop('checked') ) {
            values.append("is_freelance", true);
        } else {
            values.append("is_freelance", false);
        }
        if ( $("#is_actived").prop('checked') ) {
            values.append("is_actived", true);
        } else {
            values.append("is_actived", false);
        }
        values.append("work_time", $("#work_time").val());
        values.append("excess", $("#excess").val());
        values.append("costs", $("#costs").val());
        values.append("km_0_15", $("#km_0_15").val());
        values.append("km_15_30", $("#km_15_30").val());
        values.append("km_30_50", $("#km_30_50").val());
        values.append("km_50_80", $("#km_50_80").val());
        values.append("km_itv", $("#km_itv").val());
        values.append("km_transit", $("#km_transit").val());
        values.append("km_failure", $("#km_failure").val());
        values.append("km_broom", $("#km_broom").val());
        values.append("km_excess_tp", $("#km_excess_tp").val());
        values.append("km_excess_tp_time", $("#km_excess_tp_time").val());

        values.append("invoice_nif", $("#invoice_nif").val());
        values.append("invoice_name", $("#invoice_name").val());
        values.append("invoice_address", $("#invoice_address").val());
        values.append("invoice_zip", $("#invoice_zip").val());
        values.append("invoice_city", $("#invoice_city").val());
        values.append("invoice_province", $("#invoice_province").val());
        values.append("invoice_irpf", $("#invoice_irpf").val());


        if ( $("#edit-driver-form").valid() ) {
            drivers_edit_driver_action(auth_token, values);
        }
    });

    $("#only-actived").on( 'change', (event) => {
        event.preventDefault();
        if ( $("#only-actived").prop('checked') ) {
            localStorage.setItem('only_actived', "1");
        } else {
            localStorage.setItem('only_actived', "0");
        }

        drivers_refresh_form();

        $(".drivers-list-drivers").addClass('active');
        $(".drivers-new-driver").removeClass('active');
        hide_show('new-driver-layer','list-driver-layer',200);

        // Delete present and reload first users page
        $("#drivers-drivers-list tbody tr").remove();
        const values = {"locale": locale, "authenticity_token": $("#authenticity_token").val(), "start_load": 0};
        drivers_load_drivers_list_action(auth_token, values);

    });

    $("#is_freelance").on( 'change', () => {
        if ( $("#is_freelance").prop('checked') ) {
            $("#drivers-freelance-fields").show();
        } else {
            $("#drivers-freelance-fields").hide();
        }
    });

});

window.drivers_refresh_form = () => {
    $("#id").val('');
    $("#name").val('');
    $("#email").val('');
    $("#is_freelance").prop( 'checked', false );
    $("#is_actived").prop( 'checked', true );
    $("#work_time").val('08:30');
    $("#excess").val('12.00');
    $("#costs").val('0.00');
    $("#km_0_15").val('14.00');
    $("#km_15_30").val('17.00');
    $("#km_30_50").val('22.00');
    $("#km_50_80").val('22.00');
    $("#km_itv").val('22.00');
    $("#km_transit").val('8.00');
    $("#km_failure").val('50.00');
    $("#km_broom").val('4.00');
    $("#km_excess_tp").val('4.00');
    $("#km_excess_tp_time").val('120');
    
    $("#invoice_nif").val('');
    $("#invoice_name").val('');
    $("#invoice_address").val('');
    $("#invoice_zip").val('');
    $("#invoice_city").val('');
    $("#invoice_province").val('');
    $("#invoice_irpf").val('0.00');


    if ( drivers_print_only_actived_drivers() ) {
        $("#only-actived").prop( 'checked', true );
    }


    $("#edit-driver-form").attr( 'id', 'new-driver-form' );
    $(".btn-create-new-driver").show();
    $(".btn-edit-driver").hide();
}

window.drivers_print_bootstrap_switch_from_true_false = (val, id, field) => {
    if (val) {
        return '<div class="form-check form-switch form-switch-md text-center"><input class="form-check-input" type="checkbox" checked onchange="drivers_boolean_change_action(\''+id+'\', \''+field+'\');">';
    } else {
        return '<div class="form-check form-switch form-switch-md text-center"><input class="form-check-input" type="checkbox" onchange="drivers_boolean_change_action(\''+id+'\', \''+field+'\');">';
    }
}

window.drivers_boolean_change_action = (driver_id, field) => {
    const auth_token = localStorage.getItem('auth_token');
    const values = { "locale": $("#locale").val(), "authenticity_token": $("#authenticity_token").val(), "driver_id": driver_id, "field": field }

    spin_in();
    fetch('/'+values.locale+'/drivers/api/v1/modify_booleans',
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(values),
        },
        10000
    )
    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log(data);
        spin_out();
        if ( data.status == 401 ) {
            toast_msg(data.error_msg, 'error');
        } else if ( data.status == 200 ) {
            toast_msg(data.ok_msg, 'success');
        }

    })
    .catch((error) => {
        spin_out();
        error_msg(error);
    })

}

window.drivers_load_drivers_list_action = (auth_token, values) => {
    spin_in();

    const only_actived = drivers_print_only_actived_drivers();
    if ( drivers_print_only_actived_drivers() ){
        values["only_actived"] = true;
    }

    fetch('/'+values.locale+'/drivers/api/v1/load_drivers_list',
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(values),
        },
        10000
    )
    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log(data);
        spin_out();

        $("#drivers-drivers-list").attr('start-load', data.start_load)

        let drivers = data.drivers;

        let html = '';
        for ( let i=0; i<drivers.length; i++ ) {
            html += '<tr id="driver-'+drivers[i].id+'">';
            html +=     '<td>';
            html +=         '<div class="btn-group">';
            html +=             '<a href="#" class="fas fa-ellipsis-v" data-bs-toggle="dropdown" aria-expanded="false">';
            html +=                 '<span class="visually-hidden">Toggle Dropdown</span>';
            html +=             '</a>';
            html +=             '<ul class="dropdown-menu">';
            html +=                 '<li><a class="dropdown-item" href="javascript:drivers_edit_driver_load(\''+drivers[i].id+'\');">Editar</a></li>';
            html +=                 '<li><a class="dropdown-item" href="javascript:drivers_delete_driver_action(\''+drivers[i].id+'\',\''+drivers[i].name+'\');">Eliminar</a></li>';
            html +=             '</ul>';
            html +=         '</div>';
            html +=     '</td>';
            html +=     '<td>' + drivers[i].name + '</td>';
            html +=     '<td><a href="mailto:' + drivers[i].email + '">' + drivers[i].email + '</a></td>';
            html +=     '<td>' + drivers_print_bootstrap_switch_from_true_false(drivers[i].is_freelance, drivers[i].id, 'fr' ) + '</td>';
            html +=     '<td>' + drivers_print_bootstrap_switch_from_true_false(drivers[i].is_actived, drivers[i].id, 'ac' ) + '</td>';
            html += '</tr>';
        }

        $("#drivers-drivers-list tbody").append(html);


        if ( data.is_finished && $(".list-driver-layer").is(":visible") ) {
            $(document).Toasts('create', {
                class: 'bg-success',
                position: 'bottomRight',
                icon: "fas fa-info-circle",
                close: false,
                autohide: true,
                delay: 3000,
                title: "FINALIZADO",
                body: 'Se han cargado todos los condutores ('+data.drivers_total+').'
            });
        }

    })
    .catch((error) => {
        spin_out();
        error_msg(error);
    })
}

window.drivers_print_only_actived_drivers = () => {
    const only_actived = localStorage.getItem('only_actived');
    if ( only_actived == null ) {
        localStorage.setItem('only_actived', "0");
        return false;
    } else {
        if ( only_actived == "0" ){
            return false;
        } else {
            return true;
        }
    }
}

window.lihtusers_boolean_change_action = (user_id, field) => {
    const auth_token = localStorage.getItem('auth_token');
    const values = { "locale": $("#locale").val(), "authenticity_token": $("#authenticity_token").val(), "user_id": user_id, "field": field }

    spin_in();
    fetch('/'+values.locale+'/lihtusers/api/v1/modify_booleans',
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(values),
        },
        10000
    )
    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log(data);
        spin_out();
        if ( data.status == 401 ) {
            toast_msg(data.error_msg, 'error');
        } else if ( data.status == 200 ) {
            toast_msg(data.ok_msg, 'success');
        }

    })
    .catch((error) => {
        spin_out();
        error_msg(error);
    })

}

window.drivers_create_new_driver_action = (auth_token, values) => {
    console.log(values);
    spin_in();
    fetch('/'+values.get("locale")+'/drivers/api/v1/create_new_driver',
        {
            method: "post",
            headers: {
                //"Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: values,
        },
        10000
    )
    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log(data);
        spin_out();
        if ( data.status == 401 ) {
            toast_msg(data.error_msg, 'warning');
        } else {
            drivers_refresh_form();
            $("#id").prop( 'placeholder', data.next_id );

            $(".drivers-list-drivers").addClass('active');
            $(".drivers-new-driver").removeClass('active');
            hide_show('new-driver-layer','list-driver-layer',200);
    
            // Delete present and reload first users page
            $("#drivers-drivers-list tbody tr").remove();
            values = {"locale": $("#locale").val(), "authenticity_token": $("#authenticity_token").val(), "start_load": 0}
            drivers_load_drivers_list_action(auth_token, values);

            toast_msg(data.ok_msg, 'success');
        }

    })
    .catch((error) => {
        spin_out();
        error_msg(error);
    })
}

window.drivers_edit_driver_load = (driver_id) => {
    const auth_token = localStorage.getItem('auth_token');
    const values = { "locale": $("#locale").val(), "authenticity_token": $("#authenticity_token").val(), "driver_id": driver_id }
    spin_in();
    fetch('/'+values.locale+'/drivers/api/v1/request_driver_data_to_edit',
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(values),
        },
        10000
    )
    .then((response) => {
        return response.json()
    })
    .then((data) => {
        spin_out();
        console.log(data);

        if ( data.status == 401 ) {
            toast_msg(data.error_msg, 'error');
        } else if ( data.status == 200 ) {

            // Load data in form
            const driver = data.driver;
            // First add driver id to previous driver id
            $("#previous_id").val(driver.id);
            $("#id").val(driver.id);
            $("#name").val(driver.name);
            $("#email").val(driver.email);
            if ( driver.is_freelance ) {
                $("#is_freelance").prop( 'checked', true );
                $("#drivers-freelance-fields").show();
            } else {
                $("#is_freelance").prop( 'checked', false );
                $("#drivers-freelance-fields").hide();
            }
            if ( driver.is_actived ) {
                $("#is_actived").prop( 'checked', true );
            } else {
                $("#is_actived").prop( 'checked', false );
            }
            $("#work_time").val(driver.work_time);
            $("#excess").val(driver.excess);
            $("#costs").val(driver.costs);
            $("#km_0_15").val(driver.km_0_15);
            $("#km_15_30").val(driver.km_15_30);
            $("#km_30_50").val(driver.km_30_50);
            $("#km_50_80").val(driver.km_50_80);
            $("#km_itv").val(driver.km_itv);
            $("#km_transit").val(driver.km_transit);
            $("#km_failure").val(driver.km_failure);
            $("#km_broom").val(driver.km_broom);
            $("#km_excess_tp").val(driver.km_excess_tp);
            $("#km_excess_tp_time").val(driver.km_excess_tp_time);

            $("#invoice_nif").val(driver.invoice_nif);
            $("#invoice_name").val(driver.invoice_name);
            $("#invoice_address").val(driver.invoice_address);
            $("#invoice_zip").val(driver.invoice_zip);
            $("#invoice_city").val(driver.invoice_city);
            $("#invoice_province").val(driver.invoice_province);
            $("#invoice_irpf").val(driver.invoice_irpf);


            // Actions
            $("#new-driver-form").attr( 'id', 'edit-driver-form' );
            $(".btn-create-new-driver").hide();
            $(".btn-edit-driver").show();

            $(".drivers-new-driver").addClass('active');
            $(".drivers-list-drivers").removeClass('active');
            hide_show('list-driver-layer','new-driver-layer',200);
        }

    })
    .catch((error) => {
        spin_out();
        error_msg(error);
    })
}

window.drivers_delete_driver_action = (driver_id, driver_name) => {
    const auth_token = localStorage.getItem('auth_token');
    const values = { "locale": $("#locale").val(), "authenticity_token": $("#authenticity_token").val(), "driver_id": driver_id }
    console.log(values);  
    Swal.fire({
        title: '¿Esta seguro?',
        text: 'El conductor ' + driver_name + ' va a ser eliminado del sistema',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar'
    }).then((result) => {
        if (result.isConfirmed) {
            spin_in();
            fetch('/'+values.locale+'/drivers/api/v1/delete_driver',
                {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `bearer ${auth_token}`,
                    },
                    body: JSON.stringify(values),
                },
                10000
            )
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                console.log(data);
                spin_out();
                if ( data.status == 401 ) {
                    Swal.fire(
                        data.error_title,
                        data.error_msg,
                        'error'
                    )
                } else if ( data.status == 200 ) {
                    toast_msg(data.ok_msg, 'success')
                    $("#id").prop( 'placeholder', data.next_id );
                    $('#driver-'+values.driver_id).fadeOut(200);
                    setTimeout(() => {
                        $('#driver-'+values.driver_id).remove();
                    },200 )
                }

            })
            .catch((error) => {
                spin_out();
                error_msg(error);
            })
        }
    })

}

window.drivers_edit_driver_action = (auth_token, values) => {
    console.log(values);
    spin_in();
    fetch('/'+values.get("locale")+'/drivers/api/v1/edit_driver',
        {
            method: "post",
            headers: {
                //"Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: values,
        },
        10000
    )
    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log(data);
        spin_out();
        if ( data.status == 401 ) {
            toast_msg(data.error_msg, 'warning');
        } else if ( data.status == 402 ) {
            Swal.fire({
                icon: 'error',
                title: 'Error de ID',
                text: data.error_msg,
                footer: "MODIFIQUE EL ID Y ENVIE DE NUEVO LOS DATOS",
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Cerrar'
            });
        } else {
            drivers_refresh_form();

            $(".drivers-list-drivers").addClass('active');
            $(".drivers-new-driver").removeClass('active');
            hide_show('new-driver-layer','list-driver-layer',200);
    
            // Delete present and reload first users page
            $("#drivers-drivers-list tbody tr").remove();
            values = {"locale": $("#locale").val(), "authenticity_token": $("#authenticity_token").val(), "start_load": 0}
            drivers_load_drivers_list_action(auth_token, values);

            toast_msg(data.ok_msg, 'success');
        }

    })
    .catch((error) => {
        spin_out();
        error_msg(error);
    })
}

window.drivers_live_search_action = (auth_token, values) => {

    if ( $(".drivers-new-driver").hasClass('active') ) {
        drivers_refresh_form();

        $(".drivers-list-drivers").addClass('active');
        $(".drivers-new-driver").removeClass('active');
        hide_show('new-driver-layer','list-driver-layer',200);
    }

    fetch('/'+values.locale+'/drivers/api/v1/live_search',
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${auth_token}`,
            },
            body: JSON.stringify(values),
        },
        10000
    )
    .then((response) => {
        return response.json()
    })
    .then((data) => {
        console.log(data);
        //spin_out();

        $("#drivers-drivers-list tbody").empty();

        let drivers = data.drivers;

        let html = '';
        for ( let i=0; i<drivers.length; i++ ) {
            html += '<tr id="driver-'+drivers[i].id+'">';
            html +=     '<td>';
            html +=         '<div class="btn-group">';
            html +=             '<a href="#" class="fas fa-ellipsis-v" data-bs-toggle="dropdown" aria-expanded="false">';
            html +=                 '<span class="visually-hidden">Toggle Dropdown</span>';
            html +=             '</a>';
            html +=             '<ul class="dropdown-menu">';
            html +=                 '<li><a class="dropdown-item" href="javascript:drivers_edit_driver_load(\''+drivers[i].id+'\');">Editar</a></li>';
            html +=                 '<li><a class="dropdown-item" href="javascript:drivers_delete_driver_action(\''+drivers[i].id+'\',\''+drivers[i].name+'\');">Eliminar</a></li>';
            html +=             '</ul>';
            html +=         '</div>';
            html +=     '</td>';
            html +=     '<td>' + drivers[i].name + '</td>';
            html +=     '<td><a href="mailto:' + drivers[i].email + '">' + drivers[i].email + '</a></td>';
            html +=     '<td>' + drivers_print_bootstrap_switch_from_true_false(drivers[i].is_freelance, drivers[i].id, 'fr' ) + '</td>';
            html +=     '<td>' + drivers_print_bootstrap_switch_from_true_false(drivers[i].is_actived, drivers[i].id, 'ac' ) + '</td>';
            html += '</tr>';
        }

        $("#drivers-drivers-list tbody").append(html);

        if ( values.show_info ) {
            $(".search-mode").text(data.search_info).show();
        } else {
            $(".search-mode").empty().hide();
        }

    })
    .catch((error) => {
        spin_out();
        error_msg(error);
    })
}